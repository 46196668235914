import React, { useEffect } from "react"
import ArticleCard from "../article_card"
import { ArticleCardType } from "../../types/article_card_type"
import { LabelColor } from "../label_pill"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import GetArticlesLP from "../../graphql/get_articles_lp"

const SectionDGFresh = () => {
  const { i18n, t } = useTranslation()
  var articlesLocaleList: ArticleCardType[]

  const articles = GetArticlesLP()

  if (i18n.language == "pt") {
    articlesLocaleList = articles.articlesPT
  } else {
    articlesLocaleList = articles.articlesEN
  }

  return (
    <section className="max-w-7xl px-5 mx-auto mb-32">
      <div className="mx-5 md:mx-20 lg:mx-40 mb-10 flex flex-col md:flex-row items-center">
        <div className="hidden xl:block">
          <StaticImage
            src="../../images/logo-dgfresh-white.svg"
            alt="Blog DGFresh logo"
            width={300}
          />
        </div>

        <div className="hidden md:block xl:hidden">
          <StaticImage
            src="../../images/logo-dgfresh-white.svg"
            alt="Blog DGFresh logo"
            width={480}
          />
        </div>

        <div className="sm:hidden">
          <StaticImage
            src="../../images/logo-dgfresh-white.svg"
            alt="Blog DGFresh logo"
            width={220}
          />
        </div>

        <div className="bg-white my-6 md:my-0 md:mx-6 grow h-1 md:h-20 w-20 md:w-1"></div>

        <text className="font-poppins text-2xl lg:text-2rem text-center md:text-start font-medium leading-9">
          {t("freshContenteMadeByOurExperts")}
        </text>
      </div>
      <div className="grid grid-cols-12 justify-items-center gap-4">
        {articlesLocaleList.map(article => (
          <ArticleCard
            to={`${i18n.language}/article/${article.slug}`}
            className="flex md:basis-3/6 lg:basis-3/12 w-full col-span-12 md:col-span-4 !bg-dark-gray backdrop-blur"
            key={article.slug}
            title={article.title}
            titleColor="text-white"
            level={article.level}
            description={article.description}
            descriptionColor="text-white"
            readingTime={article.timeToRead}
            readingTimeLabelColor={LabelColor.lightGray}
            image={article.image}
          />
        ))}
      </div>
    </section>
  )
}

export default SectionDGFresh
