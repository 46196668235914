import React from "react"
import Label, { LabelColor } from "./label_pill"
import { CupIcon } from "./icons"
import { useTranslation } from "react-i18next"

type Props = {
  level?: string | null | undefined
}

const LevelLabel = ({ level }: Props) => {
  let labelColor: LabelColor
  let iconColor

  const { t } = useTranslation()

  if (level == null) {
    return <></>
  } else if (level == "begginer") {
    labelColor = LabelColor.positive
    iconColor = "fill-green-positive"
  } else if (level == "intermediate") {
    labelColor = LabelColor.attention
    iconColor = "fill-yellow-attention"
  } else {
    labelColor = LabelColor.negative
    iconColor = "fill-red-negative"
  }

  return (
    <Label color={labelColor}>
      <CupIcon className={`mr-1 ${iconColor}`} /> {t(level)}
    </Label>
  )
}

export default LevelLabel
