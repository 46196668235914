export type ArticleCardType = {
  title: string
  slug: string
  level: string
  description: string
  image?: any
  articleContent: string
  timeToRead: number
  category: string
  locale?: string | null
}

/**
 * @param data Receive the articles edges
 * @returns List of cards - ArticleCardType[]
 */
export function articlesCardsListFromMap(data: any): ArticleCardType[] {
  let card: ArticleCardType
  let articlesList: ArticleCardType[] = []

  data.map(article => {
    card = {
      title: article.node.title,
      slug: article.node.slug,
      level: article.node.level,
      description: article.node.excerpt,
      image: article.node.thumbnail?.localFile,
      articleContent: article.node.body.data.body,
      timeToRead: article.node.body.data.childMarkdownRemark.timeToRead,
      category: article.node.category,
      locale: article.node.locale,
    }

    articlesList.push(card)
  })

  return articlesList
}

/**
 * @param data Receive the article edges
 * @returns a card - ArticleCardType
 */
export function articleCardFromMap(data: any): ArticleCardType {
  var card: ArticleCardType

  data.map(article => {
    card = {
      title: article.node.title,
      slug: article.node.slug,
      level: article.node.level,
      description: article.node.excerpt,
      image: article.node.thumbnail.localFile,
      articleContent: article.node.body.data.body,
      timeToRead: article.node.body.data.childMarkdownRemark.timeToRead,
      category: article.node.category,
      locale: article.node.locale,
    }
  })

  return card!
}
