import React, { useEffect } from "react"
import LPLayout from "../components/landing-page/lp-layout"
import { SEO } from "../components/seo"
import SectionCustodyInsurence from "../components/landing-page/section-custody-insurence"
import SectionCarousel from "../components/landing-page/section-carousel"
import SectionKnowDigitra from "../components/landing-page/section-know-digitra"
import SectionJoinUs from "../components/landing-page/section-join-us"
import SectionDGFresh from "../components/landing-page/section-dgfresh"
import { StaticImage } from "gatsby-plugin-image"
import SectionOpenAccount from "../components/landing-page/section-open-account"
import SectionApi from "../components/landing-page/section-api"
import SectionPassiveIncome from "../components/landing-page/section-passive-income"

const LPIndexPage = () => {
  useEffect(() => {
    if (typeof window !== undefined && typeof document !== undefined) {
      // Cria o elemento de script
      const noscript = document.createElement('noscript');

      noscript.innerHTML = `
        <img src="https://ib.adnxs.com/pixie?pi=7b378119-73d3-4c7a-a8dc-79d1256b713b&e=PageView&script=0" width="1" height="1" style="display:none"/>
      `;

      document.body.insertBefore(noscript, document.body.firstChild);

      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-P23GV22X63');

        !function(e,i){if(!e.pixie){var n=e.pixie=function(e,i,a){n.actionQueue.push({action:e,actionValue:i,params:a})};n.actionQueue=[];var a=i.createElement("script");a.async=!0,a.src="//acdn.adnxs.com/dmp/up/pixie.js";var t=i.getElementsByTagName("head")[0];t.insertBefore(a,t.firstChild)}}(window,document);
        pixie('init', '7b378119-73d3-4c7a-a8dc-79d1256b713b');

        pixie('event', 'PageView');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
        document.body.removeChild(noscript);
      };
    }
  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render 3C134F

  return (
    <LPLayout>
      <div className="absolute bg-gradient-to-b from-new-purple from-5% via-black via-50% to-black w-full h-[60rem] z-0"></div>
      <main className="flex flex-col items-center z-10 xs:mt-12 md:mt-20">
        <SectionKnowDigitra />
        <SectionOpenAccount />
        <SectionPassiveIncome />
        <SectionCustodyInsurence />
        <SectionApi />
        <SectionCarousel />
        <SectionJoinUs />
        <SectionDGFresh />
      </main>

      <StaticImage
        src="../images/bottom-fade.svg"
        alt="Bottom fade in background"
        height={1648}
        className="!absolute w-full bottom-[19rem] z-0"
      />
    </LPLayout>
  )
}

export const Head = () => <SEO title="Digitra.com" pageType="CollectionPage" />

export default LPIndexPage
