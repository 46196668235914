import React from "react"
import CustomText from "../custom-text"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { OPEN_ACCOUNT_LINK } from "../../utils/constants"

const SectionOpenAccount = () => {
    const { t } = useTranslation()
    return (
      <section className="w-full border-t-2 border-b-2 border-gray-900 mt-[0rem] md:mt-[0rem]">
        <div className="relative grid grid-cols-12 lg:py-[5.25rem] lg:pl-20 md:p-15 p-6 lg:!text-5xl xs:text-[1.75rem] font-poppins md:!h-auto">
          <div className="flex flex-col justify-center col-span-12 grid-column-start-3 col-span-12 md:col-span-6 lg:col-span-4 mb-[2rem]">
            <CustomText className="text-[2.75rem] leading-normal !leading-15">{t("openAndEarn")}</CustomText>
            <br/>
            <form
              className="w-fit bg-purple-primary px-4 py-4 rounded-full content-center"
              action={OPEN_ACCOUNT_LINK}
              target="_blank">
              <button className="flex flex-row text-center" type="submit">
                <div className="flex flex-col">
                  <text className="text-sm font-poppins font-semibold">
                    {t("openMyAccount")}
                  </text>
                </div>
              </button>
            </form>
          </div>

          <div className="flex flex-col justify-center items-center grid-column-start-8 md:grid-column-start-0 col-span-12 md:col-span-3">
            <StaticImage
              src="../../images/hand.png"
              alt="Phone image"
            />
          </div>
        </div>
        <br/>
      </section>
    )
}

export default SectionOpenAccount
