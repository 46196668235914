import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import CustomText from "../custom-text"
import { DIGITRA_API_DOCS } from "../../utils/constants"

const SectionApi = () => {
  const { t } = useTranslation()
  return (
    <div className="w-full min-h-[28rem] md:min-h-[17.5rem] border-t-2 border-b-2 border-gray-900 mb-[8rem]">
      <div className="relative max-w-7xl px-5 m-auto font-poppins grid grid-cols-12">
        <div className="flex flex-col xs:items-center md:!items-start text-white col-span-12 md:col-span-5 my-[2rem]">
          <h1 className="text-4xl lg:text-4rem font-semibold">
            <CustomText>{t("api")}</CustomText>
          </h1>
          <br />
          <CustomText className="text-2xl lg:text-[38px] leading-10 hyphens-auto md:hyphens-none add-break-line lg:!leading-13 font-medium">{t("apiText")}</CustomText>
          <br />
          <a
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            href={DIGITRA_API_DOCS}
            aria-label={t("goToDocumentation")}
          >
            {t("goToDocumentation")}
          </a>
        </div>

        <div className="hidden xl:block !absolute bottom-[0rem] right-[0%]">
          <StaticImage
            src="../../images/notebook.png"
            alt="Computer mockup image"
            height={364}
          />
        </div>

        <div className="hidden lg:block xl:hidden !absolute bottom-[0rem] right-[4%]">
          <StaticImage
            src="../../images/notebook.png"
            alt="Computer mockup image"
            height={325}
          />
        </div>

        <div className="hidden md:block lg:hidden !absolute bottom-[0rem] right-0">
          <StaticImage
            src="../../images/notebook.png"
            alt="Computer mockup image"
            height={280}
          />
        </div>

        <div className="md:hidden !absolute -bottom-[15rem] right-[5%]">
          <StaticImage
            src="../../images/notebook.png"
            alt="Computer mockup image"
            height={280}
          />
        </div>
      </div>
    </div>
  )
}

export default SectionApi