import React from "react"
import GradientText from "./gradient-text"
import SocialMediaCard from "./social-media-card"
import {
  TWITTER_LINK,
  INSTAGRAM_LINK,
  TELEGRAM_LINK,
} from "../../utils/constants"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

const SectionJoinUs = () => {
  const { t } = useTranslation()
  return (
    <section className="flex flex-col max-w-7xl px-0 vs:px-5 mx-auto mb-32 items-center">
      <GradientText className="mb-10 text-[40px] lg:text-[5rem] font-semibold md:whitespace-nowrap">
        {t("joinUs")}
      </GradientText>
      <div className="grid grid-cols-12 gap-x-7 gap-y-5">
        <SocialMediaCard
          to={TWITTER_LINK}
          logoImage={
            <StaticImage
              src="../../images/x.png"
              alt="X (Twitter)"
              className="h-16 w-16 mb-4 mx-auto"
            />
          }
          logoName="Twitter"
        />
        <SocialMediaCard
          to={TELEGRAM_LINK}
          logoImage={
            <StaticImage
              src="../../images/telegram.svg"
              alt="Telegram"
              className="h-16 w-16 mb-4 mx-auto"
            />
          }
          logoName="Telegram"
        />
        <SocialMediaCard
          to={INSTAGRAM_LINK}
          logoImage={
            <StaticImage
              src="../../images/instagram.svg"
              alt="Instagram"
              className="h-16 w-16 mb-4 mx-auto"
            />
          }
          logoName="Instagram"
        />
      </div>
    </section>
  )
}

export default SectionJoinUs
