import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import CustomText from "../custom-text"
import { OPEN_ACCOUNT_LINK } from "../../utils/constants"

const SectionKnowDigitra = () => {
  const { t } = useTranslation()
  return (
    <section className="w-full">
        <div className="relative grid grid-cols-12 lg:py-[5.25rem] lg:pl-20 md:p-15 p-6 lg:!text-5xl xs:text-[1.75rem] font-poppins xs:mt-[3rem] md:!h-auto">
          
          <div className="flex flex-col justify-center items-center grid-column-start-2 md:grid-column-start-0 col-span-12 md:col-span-3">
            <StaticImage
              src="../../images/app.png"
              alt="Phone image"
            />
          </div>
          
          <div className="flex flex-col grid-column-start-6 md:grid-column-start-0 col-span-12 md:col-span-5">
            <CustomText className="leading-10 lg:!leading-13 justify-center items-center">{t("globalExchange")}</CustomText>
            <br/>
            <form
              className="w-fit bg-purple-primary px-4 py-4 rounded-full items-start"
              action={OPEN_ACCOUNT_LINK}
              target="_blank">
              <button className="flex flex-row" type="submit">
                <div className="flex flex-col">
                  <text className="text-sm font-poppins font-semibold">
                    {t("openMyAccount")}
                  </text>
                </div>
              </button>
            </form>
          </div>

          
        </div>
      </section>
  )
}

export default SectionKnowDigitra
