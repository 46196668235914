import React from "react"

export enum LabelColor {
  positive,
  attention,
  negative,
  gray,
  lightGray,
}

export enum FillLabelColor {
  neon,
  attention,
  primary,
  white,
}

export enum BorderColor {
  black,
}

export enum Size {
  normal,
  medium,
}

type LabelProps = {
  /**
   * Defines the color for the border and text inside
   */
  color?: LabelColor | null
  /**
   * Defines the color background and text
   */
  fillColor?: FillLabelColor | null
  /**
   * Defines the border color
   */
  borderColor?: BorderColor | null
  /**
   * Size of the label div
   */
  size?: Size | null
  children: any
}

const Label = (props: LabelProps) => {
  let labelColor
  let borderColor
  let size

  if (props.fillColor == null) {
    switch (props.color) {
      case LabelColor.positive:
        labelColor = "text-green-positive border border-green-positive"
        break
      case LabelColor.attention:
        labelColor = "text-yellow-attention border border-yellow-attention"
        break
      case LabelColor.negative:
        labelColor = "text-red-negative border border-red-negative"
        break
      case LabelColor.lightGray:
        labelColor = "text-gray-400 border border-gray-400"
        break
      default:
        labelColor = "text-gradient-black border border-gray60"
        break
    }
  } else {
    switch (props.fillColor) {
      case FillLabelColor.neon:
        labelColor = "text-black bg-green-neon"
        break
      case FillLabelColor.attention:
        labelColor = "text-black bg-yellow-attention"
        break
      case FillLabelColor.white:
        labelColor = "text-black bg-white"
        break
      default:
        labelColor = "text-white bg-purple-primary"
        break
    }

    switch (props.borderColor) {
      case BorderColor.black:
        borderColor = "border border-black"
    }
  }

  switch (props.size) {
    case Size.medium:
      size = "px-4 py-2 "
      break

    default:
      size = "px-3 py-0"
      break
  }

  return (
    <div
      className={`flex flex-row items-center rounded-full max-sm:w-min mr-2 whitespace-nowrap ${size} ${labelColor} ${borderColor}`}
    >
      {props.children}
    </div>
  )
}

export default Label
