import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import CarouselItem from "./lp-carousel"

const SectionCryptoDividends = () => {
  const { i18n, t } = useTranslation()
  var link

  if (i18n.language == "pt") {
    link = "https://digitra.com/pt/article/dividendos-cripto-ganhe-renda-passiva-com-bitcoin-e-outras-criptomoedas/"
  } else {
    link = "https://digitra.com/en/article/cryptocurrency-dividends-earn-passive-income-with-bitcoin-and-other-cryptocurrencies/"
  }

  return (
    <CarouselItem
      image={<StaticImage src="../../images/dividends.png" alt="Dividends" />}
      title={t("cryptoDividends")}
      text={t("cryptoDividendsText")}
      link={link}
      linkText={t("learnMore")}
    />
  )
}

export default SectionCryptoDividends
