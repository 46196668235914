import React from "react"
import GradientText from "./gradient-text"

type CardProps = {
  to: string
  logoImage: any
  logoName: string
}

const SocialMediaCard = ({ to, logoImage, logoName }: CardProps) => {
  return (
    <form
      className="flex items-center justify-center col-span-12 md:col-span-4 xs:mx-5 vs:mx-0 p-6 xl:p-12 rounded-3xl bg-carousel-dark backdrop-blur border border-gray-900"
      action={to}
      target="_blank"
    >
      <button className="flex flex-col items-center" type="submit">
        {logoImage}
        <GradientText className="text-[28px] lg:text-5xl font-semibold font-poppins mb-6 !leading-[65px]">
          {logoName}
        </GradientText>
      </button>
    </form>
  )
}

export default SocialMediaCard
