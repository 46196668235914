import React, { HTMLProps } from "react"
import { ClockIcon, DigitraLogoIcon } from "./icons"
import Label, { LabelColor } from "./label_pill"
import LevelLabel from "./level_label"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

type ArticleCardProps = {
  to: string
  title: string
  titleColor?: string | null
  titleSize?: string | null
  description: string
  descriptionColor?: string | null
  image?: any
  level: string | null
  readingTime: number
  readingTimeLabelColor?: LabelColor | null
  height?: number | null
  className?: HTMLProps<HTMLElement>["className"] | null
}

function ArticleCard(props: ArticleCardProps) {
  var clockIconColor

  if (props.readingTimeLabelColor != null) {
    switch (props.readingTimeLabelColor) {
      case LabelColor.positive:
        clockIconColor = "fill-green-positive"
        break
      case LabelColor.attention:
        clockIconColor = "fill-yellow-attention"
        break
      case LabelColor.negative:
        clockIconColor = "fill-red-negative"
        break
      case LabelColor.lightGray:
        clockIconColor = "fill-gray-400"
        break
      default:
        break
    }
  }

  return (
    <div
      className={`flex rounded-3xl overflow-hidden transition ease-in-out animate-fade-in bg-white shadow-md hover:shadow-2xl hover:-translate-y-1 hover:ring-4 hover:ring-purple-primary duration-300 ${props.className}`}
      style={{ height: `${props.height}px` }}
    >
      <a className="flex flex-col grow" href={props.to}>
        {props.image != null ? (
          <GatsbyImage
            className="h-56 w-full object-cover rounded-t-3xl"
            image={getImage(props.image)!}
            alt={props.title}
          />
        ) : (
          <div className="h-56 w-full bg-gradient-to-br from-gradient-light-purple to-gradient-purple flex items-center justify-center">
            <DigitraLogoIcon className="w-48 fill-white" />
          </div>
        )}

        <div className="px-3 py-6 grow grid">
          <h2
            className={`mb-6 text-center font-poppins font-medium ${
              props.titleSize ?? "text-2xl"
            } tracking-tight ${props.titleColor ?? "text-black"} `}
          >
            {props.title}
          </h2>
          <div className="flex flex-col justify-end">
            <p
              className={`mb-3 line-clamp-3 font-inter font-normal ${
                props.descriptionColor ?? "text-black-80"
              } text-center`}
            >
              {props.description}
            </p>
            <div className="mt-6 flex flex-row max-sm:flex-col max-sm:gap-y-2 max-sm:items-center justify-center">
              <LevelLabel level={props.level} />

              <Label color={props.readingTimeLabelColor ?? LabelColor.gray}>
                <ClockIcon className={`mr-1 ${clockIconColor}`} />
                {props.readingTime} min
              </Label>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

export default ArticleCard
