import React, { useEffect, useState } from "react"
import { Carousel, IconButton } from "@material-tailwind/react"
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import SectionLiquidityProvider from "./section-liquidity-provider"
import SectionCryptoDividends from "./section-crypto-dividends"

const SectionPassiveIncome = () => {
  const [activePrev, setPrev] = useState(false)
  const [activeNext, setNext] = useState(false)

  return (
    <div className="relative mb-[8rem] max-w-7xl px-5 mt-[4rem] mb-[4rem]">
      <Carousel
        className="rounded-xl items-end "
        loop={true}
        prevArrow={({ handlePrev }) => {
          useEffect(() => {
            if (activePrev) {
              handlePrev()
              setPrev(false)
            }
          }, [activePrev])

          return <></>
        }}
        nextArrow={({ handleNext }) => {
          useEffect(() => {
            if (activeNext) {
              handleNext()
              setNext(false)
            }
          }, [activeNext])

          return <></>
        }}
        navigation={({ setActiveIndex, activeIndex, length }) => (
          <div className="absolute bottom-2 md:bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
            {new Array(length).fill("").map((_, i) => (
              <span
                key={i}
                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                  activeIndex === i
                    ? "bg-gradient-light-purple w-8"
                    : "bg-carousel-grey w-4"
                }`}
                onClick={() => setActiveIndex(i)}
              />
            ))}
          </div>
        )}
      >

        <SectionLiquidityProvider />
        <SectionCryptoDividends />

      </Carousel>

      <IconButton
        onClick={() => setPrev(true)}
        aria-label="Prev item arrow"
        size="sm"
        className="!absolute sm:hidden top-[67%] -translate-y-[67%] left-[6px] rounded-full bg-white border-2 border-carousel-grey text-black"
      >
        <ArrowLeftIcon strokeWidth={2} className="w-4 h-4 " />
      </IconButton>

      <IconButton
        onClick={() => setPrev(true)}
        aria-label="Prev item arrow"
        className="!absolute hidden sm:block top-[67%] -translate-y-[67%] xl:top-[61%] xl:-translate-y-[61%] left-[0px] xl-left-0 rounded-full bg-white border-2 border-carousel-grey text-black"
      >
        <ArrowLeftIcon strokeWidth={2} className="w-6 h-6 " />
      </IconButton>

      <IconButton
        onClick={() => setNext(true)}
        aria-label="Next item arrow"
        size="sm"
        className="!absolute sm:hidden top-[67%] -translate-y-[67%]  right-[6px]  bg-white rounded-full border-2 border-carousel-grey text-black"
      >
        <ArrowRightIcon strokeWidth={2} className="w-4 h-4" />
      </IconButton>

      <IconButton
        onClick={() => setNext(true)}
        aria-label="Next item arrow"
        className="!absolute hidden sm:block top-[67%] -translate-y-[67%] xl:top-[61%] xl:-translate-y-[61%] right-[10px] xl:-right-0 bg-white rounded-full border-2 border-carousel-grey text-black"
      >
        <ArrowRightIcon strokeWidth={2} className="w-6 h-6" />
      </IconButton>
    </div>
  )
}

export default SectionPassiveIncome
