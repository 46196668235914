import React from "react"
import { useTranslation } from "react-i18next"
import CustomText from "../custom-text"
import { StaticImage } from "gatsby-plugin-image"

const SectionCustodyInsurence = () => {
  const { t } = useTranslation()
  return (
    <section className="px-5 max-w-7xl overflow-hidden mb-[8rem]">
      <div className="relative grid grid-cols-12 rounded-3xl border-2 border-purple-border lg:py-[5.25rem] lg:pl-20 md:p-15 p-6 bg-gradient-to-br from-dark-gradient-purple to-gradient-black lg:!text-5xl xs:text-[1.75rem] font-poppins md:!h-auto">
        <div className="flex flex-col justify-center col-span-12 md:col-span-8">
          <CustomText className="mb-6">{t("custodyInsurence")}</CustomText>
          <text className="leading-10 lg:!leading-13">
            <CustomText>{t("yourAssetsProtected")}</CustomText>
          </text>
        </div>

        <div className="flex flex-col justify-center items-center grid-column-start-10 col-span-12 md:col-span-3">
          <StaticImage
            src="../../images/shield.webp"
            alt="Phone image"
            height={592}
          />
        </div>
      </div>
    </section>
  )
}

export default SectionCustodyInsurence
